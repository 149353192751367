import React, { Component } from 'react';
import axios from 'axios';

// config
import config from 'config';

import { verifyEmail, verifyLength, verifyPhone, verifyLandlinePhone, verifyUrl, compare } from 'helpers/helpers';

const FormHOC = (WrappedComponent) => {
  return class FormParent extends Component {
    state = {};

    // call when user change an input & update state
    handleOnChange = (value, stateName, type, minValue, maxValue) => {
      switch (type) {
        case 'email':
          if (verifyEmail(value)) {
            this.setState({ [`${stateName}State`]: 'success' });
          } else {
            this.setState({ [`${stateName}State`]: 'error' });
          }
          break;
        case 'password':
          if (verifyLength(value, 6)) {
            this.setState({ [`${stateName}State`]: 'success' });
          } else {
            this.setState({ [`${stateName}State`]: 'error' });
          }
          break;
        case 'equalTo':
          if (compare(value, this.state[minValue])) {
            this.setState({ [`${stateName}State`]: 'success' });
          } else {
            this.setState({ [`${stateName}State`]: 'error' });
          }
          break;
        case 'phone':
          if (verifyPhone(value)) {
            this.setState({ [`${stateName}State`]: 'success' });
          } else {
            this.setState({ [`${stateName}State`]: 'error' });
          }
          break;
        case 'landlinePhone':
          if (verifyLandlinePhone(value)) {
            this.setState({ [`${stateName}State`]: 'success' });
          } else {
            this.setState({ [`${stateName}State`]: 'error' });
          }
          break;
        case 'optionnalPhone':
          if (!value) {
            this.setState({ [`${stateName}State`]: undefined });
          } else if (verifyPhone(value)) {
            this.setState({ [`${stateName}State`]: 'success' });
          } else {
            this.setState({ [`${stateName}State`]: 'error' });
          }
          break;
        case 'optionnalLandline':
          if (!value) {
            this.setState({ [`${stateName}State`]: undefined });
          } else if (verifyLandlinePhone(value)) {
            this.setState({ [`${stateName}State`]: 'success' });
          } else {
            this.setState({ [`${stateName}State`]: 'error' });
          }
          break;
        case 'length':
          if (verifyLength(value, minValue, maxValue)) {
            this.setState({ [`${stateName}State`]: 'success' });
          } else {
            this.setState({ [`${stateName}State`]: 'error' });
          }
          break;
        case 'autocomplete':
          this.setState({ [`${stateName}State`]: 'error' });
          break;
        case 'checked':
          this.setState({ [`${stateName}State`]: value ? 'success' : 'error' });
          break;
        case 'number': {
          let values = [value, minValue, maxValue].map(Number).filter(Number.isInteger);
          this.setState({
            [`${stateName}State`]: [true, values[0] >= values[1], values[0] >= values[1] && values[0] <= values[2]][
              values.length - 1
            ]
              ? 'success'
              : 'error',
          });
          break;
        }
        case 'boolean':
          this.setState({
            [`${stateName}State`]: typeof value === type ? 'success' : 'error',
          });
          break;
        case 'url':
          this.setState({
            [`${stateName}State`]: verifyUrl(value) ? 'success' : 'error',
          });
          break;
        case 'optionnalUrl':
          this.setState({
            [`${stateName}State`]: value ? (verifyUrl(value) ? 'success' : 'error') : undefined,
          });
          break;
        case 'multiSelect':
          this.setState({
            [`${stateName}State`]: verifyLength(value, 1) ? 'success' : 'error',
          });
          break;
        case 'optionnalSelect':
          value && this.setState({ [`${stateName}State`]: 'success' });
          break;
        case 'select':
        case 'date':
        case 'uniqueDate':
          this.setState({ [`${stateName}State`]: value ? 'success' : 'error' });
          break;
        case 'weekPeriod':
          minValue = minValue || 0;
          this.setState({
            [`${stateName}State`]:
              value.length >= minValue &&
              (maxValue ? value.length <= maxValue : true) &&
              value.filter((i) => !i.year || !i.week).length === 0
                ? 'success'
                : 'error',
          });
          break;
        default:
          break;
      }

      switch (type) {
        case 'email':
          this.setState({ [stateName]: value.toLowerCase() });
          break;
        case 'date':
          this.setState({
            startDate: value.startDate,
            endDate: value.endDate,
          });
          break;
        case 'autocomplete':
          this.setState({ [`${stateName}AutoComplete`]: value });
          break;
        default:
          this.setState({ [stateName]: value });
          break;
      }
    };

    // call when user submit form & check if inputs are good
    handleOnSubmit = (arr) => {
      // if elementState is undefined, set it to error
      arr.forEach((element) => {
        if (this.state[`${element}State`] === undefined) {
          this.setState({ [`${element}State`]: 'error' });
        }
      });

      // if one of the element === 'error', return false
      for (let i = 0; i < arr.length; i++) {
        let elementState = this.state[`${arr[i]}State`];
        if (elementState !== 'success') return false;

        // return true if it was the last iteration of the array
        if (i + 1 === arr.length) return true;
      }
    };

    handleOnSelect = (name, id, stateName) => {
      this.setState({
        [stateName]: id,
        [`${stateName}AutoComplete`]: name,
        [`${stateName}State`]: 'success',
      });
    };

    // clearInputs
    clearInput = (arr) => {
      arr.forEach((element) => {
        this.setState({
          [element]: undefined,
          [`${element}State`]: undefined,
        });
      });
    };

    // unvalidate an state
    unValidate = (arr) => {
      arr.forEach((element) => {
        this.setState({
          [`${element}State`]: 'error',
        });
      });
    };

    // functions related to geocoding address
    handleChangeAddress = () => {
      this.setState({
        address: '',
        addressState: 'error',
        latitude: null,
        longitude: null,
        department: '0',
      });
    };

    handleSelectAddress = (address, lat, lng, department) => {
      this.setState({
        address,
        addressState: 'success',
        latitude: lat,
        longitude: lng,
        department,
      });
    };

    // functions related to upload
    handleRemoveUploadFromState = (stateName) => {
      this.setState({
        [stateName]: null,
        [`${stateName}State`]: undefined,
      });
    };

    handleUpdateImage = (file, public_id, stateName, index = null) => {
      if (index !== null) {
        const values = this.state[stateName] || [];
        values[index] = file;
        const states = this.state[`${stateName}State`] || [];
        states[index] = 'success';
        const publicIds = this.state[`${stateName}PublicId`] || [];
        publicIds[index] = public_id;
        const loadings = this.state[`${stateName}Loading`] || [];
        loadings[index] = false;

        this.setState({
          [stateName]: [...values],
          [`${stateName}State`]: [...states],
          [`${stateName}PublicId`]: [...publicIds],
          [`${stateName}Loading`]: [...loadings],
        });
      } else {
        this.setState({
          [stateName]: file,
          [`${stateName}State`]: 'success',
          [`${stateName}PublicId`]: public_id,
          [`${stateName}Loading`]: false,
        });
      }
    };

    handleChangeImage = async (event, stateName, index = null) => {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const useIndex = index !== null;
      if (useIndex) {
        const loadingStates = this.state[`${stateName}Loading`] || [];
        loadingStates[index] = true;
        this.setState({ [`${stateName}Loading`]: [...loadingStates] });
      } else {
        this.setState({ [`${stateName}Loading`]: true });
      }

      axios
        .post(`${config.API_URL}/public/uploads/photo/${stateName}`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (useIndex) {
            const values = this.state[stateName] || [];
            values[index] = res.data.secure_url;
            const states = this.state[`${stateName}State`] || [];
            states[index] = 'success';
            const fileNames = this.state[`${stateName}OriginalFileName`] || [];
            fileNames[index] = res.data.original_name;
            const publicIds = this.state[`${stateName}PublicId`] || [];
            publicIds[index] = res.data.public_id;
            const loadings = this.state[`${stateName}Loading`] || [];
            loadings[index] = false;

            this.setState({
              [stateName]: [...values],
              [`${stateName}State`]: [...states],
              [`${stateName}OriginalFileName`]: [...fileNames],
              [`${stateName}PublicId`]: [...publicIds],
              [`${stateName}Loading`]: [...loadings],
            });
          } else {
            this.setState({
              [stateName]: res.data.secure_url,
              [`${stateName}State`]: 'success',
              [`${stateName}OriginalFileName`]: res.data.original_name,
              [`${stateName}PublicId`]: res.data.public_id,
              [`${stateName}Loading`]: false,
            });
          }
        })
        .catch(() => {
          if (useIndex) {
            const loadingStates = this.state[`${stateName}Loading`] || [];
            loadingStates[index] = false;
            this.setState({ [`${stateName}Loading`]: [...loadingStates] });
          } else {
            this.setState({ [`${stateName}Loading`]: false });
          }

          this.props.context.handleSnackbar({
            status: 'danger',
            message:
              'Un problème a eu lieu lors du téléchargement de votre photo. Est-elle bien au format .jpg ou .png ?',
          });
        });
    };

    handleRemoveImage = (stateName, index = null) => {
      if (!stateName) {
        return this.props.context.handleSnackbar({
          status: 'danger',
          message: 'Un problème a eu lieu lors de la suppression de votre fichier.',
        });
      }

      const useIndex = index !== null;
      let publicId;
      if (useIndex) {
        publicId = this.state[`${stateName}PublicId`] && this.state[`${stateName}PublicId`][index];
      } else {
        publicId = this.state[`${stateName}PublicId`];
      }

      if (!publicId) {
        return null;
      }

      axios
        .delete(`${config.API_URL}/public/uploads/photo?public_id=${publicId}`)
        .then((res) => {
          if (res.status === 200) {
            if (useIndex) {
              const values = this.state[stateName] || [];
              values.splice(index, 1);
              const states = this.state[`${stateName}State`] || [];
              states.splice(index, 1);
              const publicIds = this.state[`${stateName}PublicId`] || [];
              publicIds.splice(index, 1);
              const fileNames = this.state[`${stateName}OriginalFileName`] || [];
              fileNames.splice(index, 1);

              this.setState({
                [stateName]: [...values],
                [`${stateName}State`]: [...states],
                [`${stateName}PublicId`]: [...publicIds],
                [`${stateName}OriginalFileName`]: [...fileNames],
              });
            } else {
              this.setState({
                [stateName]: undefined,
                [`${stateName}State`]: undefined,
                [`${stateName}PublicId`]: undefined,
                [`${stateName}OriginalFileName`]: undefined,
              });
            }
          }
        })
        .catch(() => {
          this.props.context.handleSnackbar({
            status: 'danger',
            message: 'Un problème a eu lieu lors de la suppression de votre fichier.',
          });
        });
    };

    handleUpdateFile = (file, bucket, key, stateName) => {
      file &&
        this.setState({
          [stateName]: file,
          [`${stateName}Bucket`]: bucket,
          [`${stateName}Key`]: key,
          [`${stateName}State`]: 'success',
          [`${stateName}Loading`]: false,
        });
    };

    handleChangeFile = (event, stateName) => {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      this.setState({ [`${stateName}Loading`]: true });

      return axios
        .post(`${config.API_URL}/public/uploads/file/${stateName}`, formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        .then((res) => {
          const conventionResult = {
            [stateName]: res.data.secure_url,
            [`${stateName}State`]: 'success',
            [`${stateName}Bucket`]: res.data.bucket,
            [`${stateName}Key`]: res.data.key,
            [`${stateName}OriginalFileName`]: res.data.key,
            [`${stateName}Loading`]: false,
          };
          this.setState(conventionResult);
          return conventionResult;
        })
        .catch(() => {
          this.setState({
            [`${stateName}Loading`]: false,
          });
          this.props.context.handleSnackbar({
            status: 'danger',
            message: 'Un problème a eu lieu lors du téléchargement de votre fichier.',
          });
        });
    };

    handleRemoveFile = (stateName) => {
      if (!stateName) {
        return this.props.context.handleSnackbar({
          status: 'danger',
          message: 'Un problème a eu lieu lors de la suppression de votre fichier.',
        });
      }

      return axios
        .delete(
          `${config.API_URL}/public/uploads/file?bucket=${this.state[`${stateName}Bucket`]}&key=${
            this.state[`${stateName}Key`]
          }`,
        )
        .then(() => {
          this.setState({
            [stateName]: undefined,
            [`${stateName}State`]: undefined,
            [`${stateName}Bucket`]: undefined,
            [`${stateName}OriginalFileName`]: undefined,
            [`${stateName}Key`]: undefined,
          });
        })
        .catch(() => {
          this.props.context.handleSnackbar({
            status: 'danger',
            message: 'Un problème a eu lieu lors de la suppression de votre fichier.',
          });
        });
    };

    // function related to signup
    handleSignUp = (providerUrl, body, context, redirectUrl) => {
      const errorUrl = redirectUrl && '/';
      const typeUrl = body.id ? 'update_and_log_in' : 'sign_up';

      axios
        .post(`${config.API_URL}/public/auth/${typeUrl}${providerUrl}`, body)
        .then(async (res) => {
          if (res.status !== 201) {
            errorUrl && this.props.history.push(errorUrl);
            !errorUrl &&
              context.handleSnackbar({
                status: 'danger',
                message: res.data.error ? res.data.error : "L'utilisateur existe déjà",
                timeout: 15000,
              });
            return;
          }

          // quand l'admin ajoute un utilisateur, on ne change pas son context
          if (!redirectUrl) {
            context.updateUser(res.data.user);
            localStorage.setItem('token', JSON.stringify(res.data.user.token));
          }

          // redirection
          const { is_active } = res.data.user.account;
          let finalUrl = redirectUrl ? redirectUrl : is_active ? '/' : '/auth/bienvenue';
          // make pro user sign a chart after registration
          if (res.data.user.account.type === 'pro' && !res.data.user.account.has_signed_charter) {
            finalUrl = '/charte';
          }

          return this.props.history.push(finalUrl);
        })
        .catch((errorMessage) => {
          errorUrl && this.props.history.push(errorUrl);
          !errorUrl &&
            !errorMessage.response.data.CheckIfStudentExist &&
            context.handleSnackbar({
              status: 'danger',
              message: errorMessage.response.data.error
                ? errorMessage.response.data.error
                : "L'utilisateur existe déjà",
              timeout: 15000,
            });
        });
    };

    selectClass = (name) => this.setState({ selectedClass: name });

    render() {
      return (
        <WrappedComponent
          {...this.props}
          email={this.state.email}
          emailState={this.state.emailState}
          emailEleve={this.state.emailEleve}
          emailEleveState={this.state.emailEleveState}
          password={this.state.password}
          passwordState={this.state.passwordState}
          confirmPassword={this.state.confirmPassword}
          confirmPasswordState={this.state.confirmPasswordState}
          checkboxCgu={this.state.checkboxCgu}
          talkInCasualWay={this.state.talkInCasualWay}
          checkboxCguState={this.state.checkboxCguState}
          talkInCasualWayState={this.state.talkInCasualWayState}
          titleFAQ={this.state.titleFAQ}
          titleFAQState={this.state.titleFAQState}
          contentFAQ={this.state.contentFAQ}
          contentFAQState={this.state.contentFAQState}
          orderFAQ={this.state.orderFAQ}
          orderFAQState={this.state.orderFAQState}
          orderChecklist={this.state.orderChecklist}
          orderChecklistState={this.state.orderChecklistState}
          titleQuizz={this.state.titleQuizz}
          titleQuizzState={this.state.titleQuizzState}
          stepQuizz={this.state.stepQuizz}
          stepQuizzState={this.state.stepQuizzState}
          questionsQuizz={this.state.questionsQuizz}
          quizzCover={this.state.quizzCover}
          quizzCoverState={this.state.quizzCoverState}
          quizzCoverLoading={this.state.quizzCoverLoading}
          name={this.state.name} //createlass
          nameState={this.state.nameState}
          nameAutoComplete={this.state.nameAutoComplete}
          selectClass={this.selectClass}
          selectedClass={this.state.selectedClass}
          selectedClassState={this.state.selectedClassState}
          firstName={this.state.firstName}
          firstNameState={this.state.firstNameState}
          lastName={this.state.lastName}
          lastNameState={this.state.lastNameState}
          firstNameEleve={this.state.firstNameEleve}
          firstNameEleveState={this.state.firstNameEleveState}
          lastNameEleve={this.state.lastNameEleve}
          lastNameEleveState={this.state.lastNameEleveState}
          phone={this.state.phone}
          collegePhone={this.state.collegePhone}
          CollegePhoneState={this.state.CollegePhoneState}
          phoneState={this.state.phoneState}
          phoneParent={this.state.phoneParent}
          phoneParentState={this.state.phoneParentState}
          company={this.state.company}
          companyAutoComplete={this.state.companyAutoComplete}
          companyState={this.state.companyState}
          industry={this.state.industry}
          industryState={this.state.industryState}
          description_internshipState={this.state.description_internshipState}
          number={this.state.number}
          numberState={this.state.numberState}
          max_candidates={this.state.max_candidates}
          max_candidatesState={this.state.max_candidatesState}
          college={this.state.college}
          collegeAutoComplete={this.state.collegeAutoComplete}
          collegeState={this.state.collegeState}
          job={this.state.job}
          jobState={this.state.jobState}
          jobDetail={this.state.jobDetail}
          jobDetailState={this.state.jobDetailState}
          association={this.state.association}
          associationAutoComplete={this.state.associationAutoComplete}
          associationState={this.state.associationState}
          associationUrl={this.state.associationUrl}
          associationUrlState={this.state.associationUrlState}
          moderate={this.state.moderate}
          className={this.state.className}
          classNameState={this.state.classNameState}
          class={this.state.class}
          classState={this.state.classState}
          checked={this.state.checked}
          checkedState={this.state.checkedState}
          typeRep={this.state.typeRep}
          titleTuto={this.state.titleTuto}
          titleTutoState={this.state.titleTutoState}
          typeTuto={this.state.typeTuto}
          typeTutoState={this.state.typeTutoState}
          descriptionTuto={this.state.descriptionTuto}
          descriptionTutoState={this.state.descriptionTutoState}
          premium={this.state.premium}
          premiumState={this.state.premiumState}
          urlTuto={this.state.urlTuto}
          urlTutoState={this.state.urlTutoState}
          dateTuto={this.state.dateTuto}
          dateTutoState={this.state.dateTutoState}
          videoUrl={this.state.videoUrl}
          videoUrlState={this.state.videoUrlState}
          // user
          userSelect={this.state.userSelect}
          userSelectState={this.state.userSelectState}
          // text field
          titleFact={this.state.titleFact}
          contentFact={this.state.contentFact}
          ChecklistTitle={this.state.ChecklistTitle}
          ChecklistTitleState={this.state.ChecklistTitleState}
          ChecklistLink={this.state.ChecklistLink}
          ChecklistType={this.state.ChecklistType}
          ChecklistStep={this.state.ChecklistStep}
          ChecklistLinkState={this.state.ChecklistLinkState}
          ChecklistStepState={this.state.ChecklistStepState}
          ChecklistTypeState={this.state.ChecklistTypeState}
          text={this.state.text}
          textState={this.state.textState}
          newMessage={this.state.newMessage}
          newMessageState={this.state.newMessageState}
          motivationLetter={this.state.motivationLetter}
          motivationLetterState={this.state.motivationLetterState}
          declinedReasonAfterHiring={this.state.declinedReasonAfterHiring}
          declinedReasonAfterHiringState={this.state.declinedReasonAfterHiringState}
          declinedReasonAfterHiringOther={this.state.declinedReasonAfterHiringOther}
          declinedReasonAfterHiringOtherState={this.state.declinedReasonAfterHiringOtherState}
          // geocoding
          address={this.state.address}
          addressState={this.state.addressState}
          longitude={this.state.longitude}
          latitude={this.state.latitude}
          department={this.state.department}
          // upload
          logo={this.state.logo}
          logoState={this.state.logoState}
          logoLoading={this.state.logoLoading}
          picture={this.state.picture}
          pictureState={this.state.pictureState}
          pictureLoading={this.state.pictureLoading}
          educationPass={this.state.educationPass}
          educationPassState={this.state.educationPassState}
          educationPassLoading={this.state.educationPassLoading}
          diary={this.state.diary}
          diaryState={this.state.diaryState}
          diaryLoading={this.state.diaryLoading}
          curriculum={this.state.curriculum}
          curriculumState={this.state.curriculumState}
          curriculumLoading={this.state.curriculumLoading}
          motivationLetterUrl={this.state.motivationLetterUrl}
          motivationLetterUrlState={this.state.motivationLetterUrlState}
          motivationLetterUrlLoading={this.state.motivationLetterUrlLoading}
          convention={this.state.convention}
          conventionOriginalFileName={this.state.conventionOriginalFileName}
          conventionState={this.state.conventionState}
          conventionLoading={this.state.conventionLoading}
          // date
          startDate={this.state.startDate}
          startDateState={this.state.startDateState}
          weekPeriod={this.state.weekPeriod}
          weekPeriodState={this.state.weekPeriodState}
          endDate={this.state.endDate}
          dateState={this.state.dateState}
          // PJ
          PJ={this.state.PJ}
          PJState={this.state.PJState}
          PJLoading={this.state.PJLoading}
          PJOriginalFileName={this.state.PJOriginalFileName}
          // Search
          search={this.state.search}
          // errors
          isSnackbar={this.state.isSnackbar}
          status={this.state.status}
          message={this.state.message}
          // functions
          handleOnChange={this.handleOnChange}
          handleOnSubmit={this.handleOnSubmit}
          handleOnSelect={this.handleOnSelect}
          clearInput={this.clearInput}
          unValidate={this.unValidate}
          handleChangeAddress={this.handleChangeAddress}
          handleSelectAddress={this.handleSelectAddress}
          handleRemoveUploadFromState={this.handleRemoveUploadFromState}
          handleUpdateImage={this.handleUpdateImage}
          handleUpdateFile={this.handleUpdateFile}
          handleChangeImage={this.handleChangeImage}
          handleChangeFile={this.handleChangeFile}
          handleRemoveImage={this.handleRemoveImage}
          handleRemoveFile={this.handleRemoveFile}
          handleSignUp={this.handleSignUp}
          source={this.state.source}
        />
      );
    }
  };
};

export default FormHOC;
