import React from 'react';
import PropTypes from 'prop-types';

import { Context } from 'context/Context';

import config from 'config';
import axios from 'axios';

class DisconnectionPage extends React.Component {
  async componentDidMount() {
    const { context } = this.props;

    if (!localStorage.getItem('admin')) {
      localStorage.removeItem('token');
      context.updateUser({});
      this.props.history.push('/auth/connexion');
    } else {
      const admin = JSON.parse(localStorage.getItem('admin'));
      localStorage.setItem('token', JSON.stringify(admin.token));
      localStorage.removeItem('admin');
      context.updateUser(admin);
      this.props.history.push('/tableau-de-bord');
    }
    const logmessage = {
      type: 'success',
      content: `${this.props.context.state.user.account.type.toUpperCase()} : ${
        this.props.context.state.user.email
      } s'est déconnecté`,
    };
    if (
      this.props.context.state.user.account.type === 'student' ||
      this.props.context.state.user.account.type === 'pro'
    ) {
      await axios.post(`${config.API_URL}/api/logs`, logmessage);
    }
  }

  render() {
    return <div />;
  }
}

DisconnectionPage.propTypes = {
  context: PropTypes.object.isRequired,
};

// eslint-disable-next-line react/display-name
export default (props) => (
  <Context.Consumer>{(context) => <DisconnectionPage {...props} context={context} />}</Context.Consumer>
);
