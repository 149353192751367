import React, { useEffect, useState } from 'react';
import SweetModal from '../../components/SweetModal/SweetModal';
import InfoCollegeForm from '../../views/College/InfoCollegeForm';
import axios from 'axios';
import config from '../../config';
import { WeekPeriod } from '../InternshipOffersPage/InternshipOffersPageService';

interface ProfileCollegeModalProps {
    user: any
}

interface College {
    internship_period: WeekPeriod[];
    name: string;
    full_name: string;
}
 
const ProfileCollegeModal: React.FC<ProfileCollegeModalProps> = (props: ProfileCollegeModalProps) => {
  // STATES
  const [college, setCollege] = useState<College>();
  const [initWeekPeriod, setInitWeekPeriod] = useState<WeekPeriod[]>();
  const [selectedWeekPeriod, setSelectedWeekPeriod] =  useState<WeekPeriod[]>();
  const [modalOpen, setModalOpen] =  useState<boolean>(true);

  useEffect(() => {
    getCollege();
  }, [setCollege, setInitWeekPeriod]);

  // METHODS
  const getCollege = async (): Promise<void> => {
      const response = await axios.get(
        `${config.API_URL}/api/users/college/${props.user._id}/info`
      );

      setCollege(response.data);
      setInitWeekPeriod(response.data.internship_period);
  };

  const collegeHasWeekPeriod = (): boolean => {
    return college.internship_period && college.internship_period.length > 0
  }

  const updateCollegeWeekPeriod = async (): Promise<void> => {
    // No error handling, close modal and pass this step if any error
    await axios.put(`${config.API_URL}/api/users/college/${props.user._id}/info`, {
      internship_period: selectedWeekPeriod
    });
    setModalOpen(false)
  };

  if(!college || collegeHasWeekPeriod()) {
    return (<></>)
  }
  
    return ( 
    <SweetModal
        key='force-week-periods'
        title="Action requise"
        noCancel
        maxWidth="lg"
        noClose
        submit={updateCollegeWeekPeriod}
        isOpen={modalOpen}
        inputs={
          [
            <div>
              <strong>
              Veuillez indiquer les semaines de stage de votre collège pour l’année scolaire en cours
              </strong>
              <InfoCollegeForm college={college.full_name} initWeekPeriod={initWeekPeriod} onSelect={(periods)=> {setSelectedWeekPeriod(periods)}} />
            </div>,
          ]}
        toggleModal={() => {}}
        submitDisabled={!selectedWeekPeriod || selectedWeekPeriod.length < 1}
      />);
}
 
export default ProfileCollegeModal;