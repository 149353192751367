import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import { ArrowBack, ArrowForward, ExpandMore } from '@material-ui/icons';

import SweetModal from 'components/SweetModal/SweetModal';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from './CustomInput';
import CustomChip from 'components/Miscellaneous/CustomChip';

import multipleWeeksPickerStyle from './style/multipleWeeksPickerStyle';

import {
  format,
  startOfISOWeekYear,
  endOfISOWeekYear,
  getISOWeeksInYear,
  addDays,
  addWeeks,
  getDayOfYear,
  getYear,
  getISOWeek,
} from 'date-fns';

import { toYearWeek } from '../../helpers/helpers';

class MultipleWeeksPicker extends Component {
  constructor() {
    super();
    this.handleOpen = this.handleOpen.bind(this);
  }

  state = {
    isOpen: false,
    currentDate: '',
    currentYear: '',
    selectedYear: '',
    selectedAllWeeks: [],
    selectedWeeks: [],
    dateFormat: {
      toView: 'dd/MM/yyyy',
      toReturn: 'yyyy-MM-dd HH:mm:ss.SSS',
    },
    yearInfo: {},
    isPro: false,
  };

  _mounted = false;

  UNSAFE_componentWillMount() {
    const { isPro } = this.props;

    this._mounted = true;

    const currentDate = new Date();
    const currentYear = getYear(currentDate);
    this.setState(
      {
        currentDate,
        currentYear,
        selectedWeeks: [],
        selectedAllWeeks: [],
        selectedYear: currentYear,
        isPro: isPro || this.state.isPro,
      },
      () => {
        this.addElements(
          'selectedWeeks',
          this.props.value.map((data) => data.year && data.week && (data.yearWeek || toYearWeek(data.year, data.week))),
        );
      },
    );
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  setState(state, callback) {
    return this._mounted && super.setState(state, callback);
  }

  range = (start, end, step = 1) => {
    const allNumbers = [start, end, step].every(Number.isFinite);
    if (!allNumbers) throw new TypeError('range() expects only finite numbers as arguments.');
    if (step <= 0) throw new Error('step must be a number greater than 0.');
    if (start > end) step = -step;
    const length = Math.floor(Math.abs((end - start) / step)) + 1;
    return Array.from(Array(length), (x, index) => start + index * step);
  };

  getYearInfo = (year) => {
    if (!this.state.yearInfo[year]) {
      const dateInYear = new Date(`${year}-07-01`);
      let start = startOfISOWeekYear(dateInYear);
      let end = endOfISOWeekYear(dateInYear);
      let nbWeeks = getISOWeeksInYear(dateInYear);
      let disabledWeeks;
      if (this.state.isPro && Number(year) < this.state.currentYear) {
        disabledWeeks = Array.from(Array(53).keys());
      } else if (this.state.isPro && Number(year) === this.state.currentYear) {
        let currentWeekNumber = getISOWeek(this.state.currentDate);
        disabledWeeks = Array.from(Array(currentWeekNumber).keys());
      }
      let toReturn = {
        start,
        end,
        nbWeeks,
        weeks: this.range(1, nbWeeks).map((week) => ({
          key: toYearWeek(year, week),
          year: Number(year),
          week,
          // FIXME
          disabled: [
            // NOUVEL AN
            1,
            // TOUSSAINT
            43,
            44,
            // NOËL
            Math.ceil(getDayOfYear(new Date(`${year}-12-24`)) / 7),
            // SAINT SILVESTRE
            53,
            ...disabledWeeks,
          ].includes(week),
          start: addWeeks(start, week - 1),
          end: addDays(addWeeks(start, week - 1), 4),
        })),
      };

      this.setState((prevState) => ({
        ...prevState,
        yearInfo: { ...prevState.yearInfo, [year]: toReturn },
      }));

      return toReturn;
    }
    return this.state.yearInfo[year];
  };

  inputLabel = () =>
    this.state.selectedWeeks.length === 0
      ? 'Choisissez les semaines'
      : `${this.state.selectedWeeks.length} semaine(s) séléctionnée(s)`;

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => this.setState({ isOpen: false });

  handlePrev = () =>
    this.setState((prevState) => ({
      ...prevState,
      selectedYear: prevState.selectedYear - 1,
    }));

  handleNext = () =>
    this.setState((prevState) => ({
      ...prevState,
      selectedYear: prevState.selectedYear + 1,
    }));

  convertSelectedWeeksToYearInfo = (selectedWeeks) => {
    return selectedWeeks
      .map((key) => {
        let [year] = key.toString().split('-').map(Number);
        let yearInfo = this.state.yearInfo[year] ? this.state.yearInfo[year] : this.getYearInfo(year);
        return yearInfo.weeks.find((i) => i.key === key);
      })
      .filter((_) => _);
  };

  handleSubmit = () => {
    this.props.onSelect(this.convertSelectedWeeksToYearInfo(this.state.selectedWeeks));
    this.handleClose();
  };

  onDeleteWeekChip = (key) => {
    const selectedWeeksAfterDelete = this.state.selectedWeeks.filter((week) => week != key);
    this.setState({ selectedWeeks: selectedWeeksAfterDelete });
    this.props.onSelect(this.convertSelectedWeeksToYearInfo(selectedWeeksAfterDelete));
  };

  inSelectedWeeks = (key) => this.state.selectedWeeks.includes(key);

  addElements = (key, values) => {
    if (!Array.isArray(values)) values = [values];
    if (key === 'selectedWeeks') {
      values = values
        .map((value) => {
          let yw = value.toString().split('-');
          if (Object.prototype.hasOwnProperty.call(this.state.yearInfo, yw[0])) this.getYearInfo(yw[0]);
          let weekInfo = this.state.yearInfo[yw[0]] && this.state.yearInfo[yw[0]].weeks.filter((i) => i.key === value);
          return weekInfo && weekInfo[0] && weekInfo[0].disabled ? null : value;
        })
        .filter((_) => _);
    }
    this.setState((prevState) => ({
      [key]: [...prevState[key], ...values].sort(),
    }));
  };

  // noinspection JSUnusedGlobalSymbols
  removeElements = (key, values) => {
    if (!Array.isArray(values)) values = [values];
    this.setState((prevState) => ({
      [key]: prevState[key].filter((elm) => !values.includes(elm)),
    }));
  };

  toggleSelectWeek = (key) => {
    const { limit } = this.props;
    const { selectedWeeks } = this.state;
    const isSelected = this.inSelectedWeeks(key);
    return (
      selectedWeeks.length + (isSelected ? -1 : 0) !== limit &&
      this[`${isSelected ? 'remove' : 'add'}Elements`]('selectedWeeks', [key])
    );
  };

  /**
   *
   * @param {*} yearInfo - weeks, start date end date ...
   * @param {*} currentDateWeek - n° of week in year
   * @returns
   * - only future weeks for current year if disabled past
   * - all weeks when current year < selected year
   * - all weeks event in past when disabled past is false
   */
  filterDisplayedWeeks = (yearInfo, currentDateWeek) => {
    return yearInfo.weeks.filter((week) =>
      this.props.disablePast && this.state.currentYear === this.state.selectedYear ? week.week > currentDateWeek : true,
    );
  };

  renderYear = () => {
    const {
      isOpen,
      currentDate,
      selectedYear,
      dateFormat: { toView },
    } = this.state;

    if (isOpen) {
      const { limit } = this.props;
      const currentDateWeek = getISOWeek(currentDate);
      const getYearInfo = this.getYearInfo(selectedYear);
      let filteredWeek = this.filterDisplayedWeeks(getYearInfo, currentDateWeek);
      return (
        <Table>
          <TableHead>
            <TableRow align='left'>
              <TableCell>
                {!limit && (
                  <Fragment>
                    <CustomCheckbox
                      color='primary'
                      name={`${selectedYear}-all`}
                      checked={this.state.selectedAllWeeks.includes(selectedYear)}
                      onClick={({ target: { checked } }) => {
                        let method = `${checked ? 'add' : 'remove'}Elements`;
                        this[method]('selectedAllWeeks', [selectedYear]);
                        this[method](
                          'selectedWeeks',
                          filteredWeek.filter((e) => !e.disabled).map((e) => e.key),
                        );
                      }}
                    />
                    {' Tout Sélectionner'}
                  </Fragment>
                )}
              </TableCell>
              <TableCell align='center'>Du</TableCell>
              <TableCell align='center'>Au</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredWeek.map((datas) => (
              <TableRow key={datas.key}>
                <TableCell align='left'>
                  <CustomCheckbox
                    color='primary'
                    value={datas.key}
                    disabled={
                      datas.disabled
                        ? datas.disabled
                        : !this.inSelectedWeeks(datas.key) && this.state.selectedWeeks.length === limit
                    }
                    checked={this.inSelectedWeeks(datas.key)}
                    onChange={() => this.toggleSelectWeek(datas.key)}
                  />
                  <span>Semaine {datas.week}</span>
                </TableCell>
                <TableCell align='center'>{format(new Date(datas.start), toView)}</TableCell>
                <TableCell align='center'>{format(new Date(datas.end), toView)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
  };

  renderCalendar = () => {
    const { classes, disablePast, disableFuture } = this.props;
    const { isOpen, currentYear, selectedYear } = this.state;
    if (isOpen) {
      let canBack = true;
      let canForward = true;
      if (disablePast && selectedYear === currentYear) canBack = false;
      if (disableFuture && selectedYear === currentYear) canForward = false;

      const arrowBackClasses = cx({
        [classes.arrowCannot]: !canBack,
        [classes.arrowCan]: canBack,
      });

      const arrowForwardClasses = cx({
        [classes.arrowCannot]: !canForward,
        [classes.arrowCan]: canForward,
      });

      return (
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <ArrowBack className={arrowBackClasses} onClick={() => canBack && this.handlePrev()} />
            <span className={classes.selectedYear}>{selectedYear}</span>
            <ArrowForward className={arrowForwardClasses} onClick={() => canForward && this.handleNext()} />
          </Paper>
          {this.renderHeaderMessage()}
          <small>{this.props.hideSubtitle == undefined ? this.inputLabel() : false}</small>
          {this.renderYear()}
        </div>
      );
    } else {
      return [];
    }
  };

  renderHeaderMessage = () => {
    const { classes, limit, headerMessage } = this.props;
    let messages = [
      limit && (
        <Fragment key='limit-message'>
          Merci de sélectionner les semaines de stage de votre collège <br />({limit} maximum)
        </Fragment>
      ),
      headerMessage,
    ].filter((_) => _);
    return messages && <h4 className={classes.title}>{messages}</h4>;
  };

  // noinspection JSUnresolvedVariable
  renderModal = () => (
    <SweetModal
      maxWidth='md'
      title={this.props.title || 'Période de disponibilité'}
      isOpen={this.state.isOpen}
      inputs={this.renderCalendar()}
      toggleModal={this.handleClose}
      submit={this.handleSubmit}
    />
  );

  render = () => {
    const { classes, success, error, label } = this.props;
    const { isOpen, currentYear, selectedWeeks } = this.state;

    const arrowDropDownClasses = cx({
      [classes.arrowDropDown]: true,
      [classes.arrowDropDownOpen]: isOpen,
    });

    const haveOtherYears =
      [selectedWeeks.map((wy) => wy.toString().split('-').map(Number)[0]).filter((y) => y !== Number(currentYear))]
        .length > 0;

    return (
      <div className={classes.container}>
        {this.renderModal()}
        <div className={classes.container}>
          <CustomInput
            success={success}
            error={error}
            labelText={label}
            value={this.inputLabel()}
            inputProps={{
              readOnly: true,
              onClick: this.handleOpen,
            }}
          />
          <ExpandMore className={arrowDropDownClasses} />
        </div>
        {selectedWeeks.length === 0 && this.props.isCollegeForm && (
          <div className={classes.errorText}>Merci d'ajouter au moins une semaine de disponibilité</div>
        )}
        <div>
          {selectedWeeks.map((key) => {
            this.getYearInfo(key.toString().split('-')[0]);
            let [year] = key.toString().split('-').map(Number);
            if (this.state.yearInfo[year]) {
              let weekInfo = this.state.yearInfo[year].weeks.find((i) => i.key === key);
              return (
                <CustomChip
                  key={`selected_chip_${key}`}
                  sep
                  bold
                  color='primary'
                  onDelete={() => this.onDeleteWeekChip(key)}>
                  s{weekInfo.week} - {format(Date.parse(weekInfo.start), `dd/MM${haveOtherYears ? '/yyyy' : ''}`)} au{' '}
                  {format(Date.parse(weekInfo.end), `dd/MM${haveOtherYears ? '/yyyy' : ''}`)}
                </CustomChip>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  };
}

MultipleWeeksPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  disablePast: PropTypes.bool,
  title: PropTypes.string,
  headerMessage: PropTypes.node,
  onSelect: PropTypes.func,
  value: PropTypes.array,
  isPro: PropTypes.bool,
  limit: PropTypes.number,
  hideSubtitle: PropTypes.bool,
  isCollegeForm: PropTypes.bool,
};

MultipleWeeksPicker.defaultProps = {
  isCollegeForm: false,
};

// noinspection JSUnusedGlobalSymbols
export default withStyles(multipleWeeksPickerStyle)(MultipleWeeksPicker);
