// @material-ui/icons
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Description from '@material-ui/icons/Description';
import Add from '@material-ui/icons/Add';
import PersonAdd from '@material-ui/icons/PersonAdd';

export const routes = [
  {
    user: true,
    path: '/deconnexion',
    name: 'Déconnexion',
    icon: PowerSettingsNew,
  },
  {
    path: '/tableau-de-bord',
    name: 'Tableau de bord',
    icon: DashboardIcon,
  },
  {
    path: '/annonces',
    name: 'Mes offres de stage',
    icon: Description,
  },
  {
    path: '/offre-type',
    name: "Créer un modèle d'offre",
    icon: Add,
  },
  {
    path: '/ajouter-un-tuteur',
    name: 'Ajouter un tuteur',
    icon: PersonAdd,
  },
];
