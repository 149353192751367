/*eslint no-extend-native: ["error", { "exceptions": ["Number"] }]*/

exports.fakePassword = (length = 6) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let password = '';

  for (let i = 0, n = charset.length; i < length; i++) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
};

exports.matchPublicIdCloudinary = (path, url) => {
  if (!url) return null;

  const expression = `${path}[^.]*`;
  const regex = new RegExp(expression, 'g');

  return url.match(regex) ? url.match(regex)[0] : null;
};

exports.matchPublicIdAWS = (url) => {
  if (!url) return null;

  const urlSplited = url.split(/[\s/]+/); // split url on '/'

  const regex = /^([^.]+)/g; // after until first dot
  const bucket = `${urlSplited[1].match(regex)}/${urlSplited[2]}`;
  const key = urlSplited[3];

  return { bucket, key };
};

exports.readablePhone = (value) => {
  if (!value) return null;

  const modifiedPhone = value.slice(4, 13);
  return `0${modifiedPhone}`;
};

// transform phone to a valid number to send SMS
exports.transformPhone = (value) => {
  if (!value) return null;

  const beginningNumber = ['01', '02', '03', '04', '05', '06', '07', '08', '09'];

  let numericPhone = value.replace(/\D/g, '');
  const first2characters = numericPhone.slice(0, 2);

  if (beginningNumber.includes(first2characters)) {
    numericPhone = numericPhone.slice(1, 10);
    numericPhone = `0033${numericPhone}`;
  } else if (first2characters === '33') {
    numericPhone = `00${numericPhone}`;
  }

  return numericPhone;
};

exports.formatPhone = (value) => {
  if (!value) return null;

  value = [...value.replace('0033', '0')];
  return new Array(Math.ceil(value.length / 2))
    .fill(undefined, undefined, undefined)
    .map(() => value.splice(0, 2).join(''))
    .join(' ');
};

// function that returns true if value is a french mobile phone, false otherwise
exports.verifyPhone = (value) => {
  if (!value) return false;

  const result = /^(?:(?:\+|00)33|0)\s*[6-7](?:[\s.-]*\d{2}){4}/.exec(value);
  return result && result[0] === value;
};

exports.verifyLandlinePhone = (value) => {
  if (!value) return false;

  const result = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/.exec(value);
  return result && result[0] === value;
};

// function that verifies if a string has a given length or not
exports.verifyLength = (value, min, max) => {
  const length = value ? value.length : 0;
  if (max) {
    if (length >= min && length <= max) {
      return true;
    }
  } else {
    if (length >= min) {
      return true;
    }
  }
  return false;
};

// function that returns true if value is email, false otherwise
exports.verifyEmail = (value) => {
  let emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRex.test(value);
};

exports.verifyUrl = (value) => {
  let urlRex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return urlRex.test(value);
};

// function that returns true if the two values are egal, false otherwise
exports.compare = (string1, string2) => string1 === string2;

exports.hasKey = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

exports.isPerfectScrollbar = () => {
  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== 'undefined';
  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;
  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;

  // check mac os
  let isPerfectScrollbar = !navigator.platform.indexOf('Mac') === 0;

  if (isFirefox || isIE || isEdge) {
    isPerfectScrollbar = true;
  }

  return isPerfectScrollbar;
};

exports.isIE = () => /*@cc_on!@*/ false || !!document.documentMode;

exports.formattedDate = (dateString) => {
  let date = new Date(dateString);
  date.setHours(0, 0, 0); // Set hours, minutes and seconds
  return date.toString();
};

exports.toYearWeek = (year, week) => [year, week.toString().padStart(2, '0')].join('-');

exports.currentScolarYear = (separator) => {
  const SEPTEMBER_INDEX = 8;
  const currentDate = new Date();
  if (currentDate.getMonth() >= SEPTEMBER_INDEX) {
    return `${currentDate.getFullYear()}${separator}${currentDate.getFullYear() + 1}`;
  }
  return `${currentDate.getFullYear() - 1}${separator}${currentDate.getFullYear()}`;
};

exports.toSlug = (value) => {
  const rExps = [
    { re: /[\xC0-\xC6]/g, ch: 'A' },
    { re: /[\xE0-\xE6]/g, ch: 'a' },
    { re: /[\xC8-\xCB]/g, ch: 'E' },
    { re: /[\xE8-\xEB]/g, ch: 'e' },
    { re: /[\xCC-\xCF]/g, ch: 'I' },
    { re: /[\xEC-\xEF]/g, ch: 'i' },
    { re: /[\xD2-\xD6]/g, ch: 'O' },
    { re: /[\xF2-\xF6]/g, ch: 'o' },
    { re: /[\xD9-\xDC]/g, ch: 'U' },
    { re: /[\xF9-\xFC]/g, ch: 'u' },
    { re: /[\xC7-\xE7]/g, ch: 'c' },
    { re: /[\xD1]/g, ch: 'N' },
    { re: /[\xF1]/g, ch: 'n' },
  ];

  // Converti les caractères accentués en leurs équivalent alpha
  for (let i = 0, len = rExps.length; i < len; i++) {
    value = value.replace(rExps[i].re, rExps[i].ch);
  }

  return value
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '')
    .replace(/-{2,}/g, '-');
};

exports.removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') exports.removeEmpty(obj[key]);
    // recurse
    else if (obj[key] == null) delete obj[key]; // delete
  });
  return obj;
};

const restArguments = (func, startIndex) => {
  startIndex = startIndex == null ? func.length - 1 : +startIndex;
  return function () {
    let length = Math.max(arguments.length - startIndex, 0),
      rest = Array(length),
      index = 0;
    for (; index < length; index++) {
      rest[index] = arguments[index + startIndex];
    }
    // eslint-disable-next-line default-case
    switch (startIndex) {
      case 0:
        return func.call(this, rest);
      case 1:
        return func.call(this, arguments[0], rest);
      case 2:
        return func.call(this, arguments[0], arguments[1], rest);
    }
    let args = Array(startIndex + 1);
    for (index = 0; index < startIndex; index++) {
      args[index] = arguments[index];
    }
    args[startIndex] = rest;
    return func.apply(this, args);
  };
};

const delay = restArguments((func, wait, args) => {
  return setTimeout(() => {
    return func.apply(null, args);
  }, wait);
});

exports.debounce = (func, wait, immediate) => {
  let timeout, result;

  let later = function (context, args) {
    timeout = null;
    if (args) result = func.apply(context, args);
  };

  let debounced = restArguments(function (args) {
    if (timeout) clearTimeout(timeout);
    if (immediate) {
      let callNow = !timeout;
      timeout = setTimeout(later, wait);
      if (callNow) result = func.apply(this, args);
    } else {
      timeout = delay(later, wait, this, args);
    }

    return result;
  });

  debounced.cancel = function () {
    clearTimeout(timeout);
    timeout = null;
  };

  return debounced;
};

const throttle = (fn, ms, args = []) => {
  let timeout;
  const exec = () => fn.apply(null, args);
  const clear = () => (timeout === undefined ? null : clearTimeout(timeout));
  if (fn !== undefined && ms !== undefined) timeout = setTimeout(exec, ms);
  // API to clear the timeout
  throttle.clearTimeout = function () {
    clear();
  };
};

exports.throttle = throttle;

exports.truncate = (str, n, useWordBoundary = true, ellipseString = '&hellip;') => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + ellipseString;
};

exports.addressToCity = (address) => {
  let city;
  let regex = [
    /^[0-9]{5} (.*), .*$/,
    /^.*, [0-9]{5} (.*)$/,
    /^.*, (.*), .*$/,
    /^.*, .* (.*), .*$/,
    /^(.*), .*$/,
    /^(\w+)$/,
  ];
  let tests = regex.map((r) => r.test(address));

  if (tests.some((test) => test)) {
    if (tests[0]) city = address.replace(regex[0], '$1');
    else if (tests[1]) city = address.replace(regex[1], '$1');
    else if (tests[2]) city = address.replace(regex[2], '$1');
    else if (tests[3]) city = address.replace(regex[3], '$1');
    else if (tests[4]) city = address.replace(regex[4], '$1');
    else if (tests[5]) city = address;
  }

  return city;
};

exports.nl2br = (str, replaceMode, isXhtml = false) => {
  let breakTag = isXhtml ? '<br />' : '<br>';
  let replaceStr = replaceMode ? `$1${breakTag}` : `$1${breakTag}$2`;
  return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
};

exports.uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

exports.arrayEqual = (arr1, arr2) =>
  arr1.length === arr2.length &&
  arr1.every((item) => arr2.indexOf(item) > -1) &&
  arr2.every((item) => arr1.indexOf(item) > -1);

exports.isObjectId = (str) => /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i.test(str);
