// ##############################
// // // Sidebar styles
// #############################

import { drawerWidth, transition, primaryColor, whiteColor } from 'assets/styles/jss/main';

const sidebarStyle = (theme) => ({
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: '1032',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    boxShadow: 'none',
    //touchAction: "none",
    //...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth,
      //...boxShadow,
      position: 'fixed',
      top: '0',
      height: '100vh',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '-1',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0',
    },
  },
  drawerPaperRight: {
    [theme.breakpoints.down('sm')]: {
      right: '0',
      left: 'auto',
    },
  },
  blackBackground: {
    color: '#FFFFFF',
    '&:after': {
      background: '#000',
      opacity: '.8',
    },
  },
  blueBackground: {
    color: '#FFFFFF',
    '&:after': {
      background: '#00acc1',
      opacity: '.93',
    },
  },
  whiteBackground: {
    color: '#3C4858',
    '&:after': {
      background: primaryColor,
    },
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'all 300ms linear',
  },
  list: {
    //marginTop: "15px",
    padding: '30px 16px 30px 20px !important',
    //padding: "0 16px !important",
    marginBottom: '0',
    listStyle: 'none',
    color: 'inherit',
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
  },
  whiteSep: {
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '35%',
      height: '1px',
      width: '25%',
      backgroundColor: 'hsla(0,0%,100%,.3)',
    },
  },
  item: {
    color: whiteColor,
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    padding: '0',
  },
  userItem: {
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  welcomeItem: {
    fontSize: 14,
    fontWeight: 500,
    padding: '14px 0',
  },
  functionItem: {
    textTransform: 'uppercase',
  },
  contextItem: {
    background: whiteColor,
    color: primaryColor,
    borderRadius: 4,
    '& a': {
      color: primaryColor,
    },
  },
  itemLink: {
    display: 'flex',
    alignItems: 'end',
    padding: '10px 0',
    transition: 'all 300ms linear',
    position: 'relative',
    backgroundColor: 'transparent',
    width: 'auto',
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  itemIcon: {
    color: whiteColor,
    minWidth: '24px',
    marginRight: '15px',
  },
  itemIconWhite: {
    color: primaryColor,
  },
  itemText: {
    flex: 'inherit',
    margin: '0',
    padding: '0',
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: '500',
    transform: 'translate3d(0px, 0, 0)',
    opacity: '1',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    borderBottom: '5px solid transparent',
  },
  itemTextWrap: {
    whiteSpace: 'inherit',
    lineHeight: 2,
  },
  itemTextActive: {
    borderBottomColor: '#FFF',
  },
  userItemText: {
    fontSize: 16,
    lineHeight: '22px',
  },
  collapseList: {
    marginTop: '0',
  },
  collapseItem: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '10px 0 0 0',
    padding: '0',
  },
  collapseActive: {
    outline: 'none',
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    boxShadow: 'none',
  },
  collapseItemLink: {
    transition: 'all 300ms linear',
    margin: '0 15px',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px',
    backgroundColor: 'transparent',
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.2)',
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: whiteColor,
    },
  },
  collapseItemMini: {
    color: 'inherit',
    //...customFont,
    textTransform: 'uppercase',
    width: '30px',
    marginRight: '15px',
    textAlign: 'center',
    letterSpacing: '1px',
    position: 'relative',
    float: 'left',
    display: 'inherit',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
  },
  collapseItemText: {
    color: 'inherit',
    margin: '0',
    position: 'relative',
    transform: 'translateX(0px)',
    opacity: '1',
    whiteSpace: 'nowrap',
    display: 'block',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
  },
  collapseItemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
  },
  sidebarWrapper: {
    position: 'relative',
    overflow: 'auto',
    width: '260px',
    zIndex: '4',
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    paddingBottom: '30px',
  },
  user: {
    //paddingBottom: "20px",
    //margin: "50px auto 0",
    /*"&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      right: "15px",
      height: "1px",
      width: "calc(100% - 30px)",
      backgroundColor: "hsla(0,0%,100%,.3)"
    }*/
  },
  avatarImg: {
    maxHeight: '100%',
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  userCollapseButton: {
    margin: '0',
    padding: '6px 15px',
    '&:hover': {
      background: 'none',
    },
  },
  userCollapseLinks: {
    marginTop: '-4px',
  },
});

export default sidebarStyle;
