
import { Grid } from '@material-ui/core';
import React from 'react';
import { WrappedResponse } from '../../InternshipOffersPage/InternshipOffersPageService';
import CircularLoader from '../../Loader/CircularLoader';
import useStudentGet, { Student } from './GetStudentByIdService';
import { StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';
import FaceIcon from '@material-ui/icons/Face';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import DescriptionIcon from '@material-ui/icons/Description';
import { Context } from '../../../context/Context';

interface ViewProps extends WithStyles<typeof styles> {
    studentResponse: WrappedResponse<Student>,
    context: {
      state : {
        user : {
          account : {
            type : string
          }
        },
      },
    },
}

const styles: StyleRules = {
  title: {
    padding: "15px",
    fontSize: "28px",
    textTransform: "uppercase",
  },
  iconAlign:{
    display: "flex",
    alignItems: "center"
  },
  textAlign:{
    display: "inline",
    padding: "0 0 0 10px"
  },
  button: {
    display: "flex",
    alignItems: "center",
    boxShadow: 'none',
    fontWeight: 500,
    fontSize: 16,
    padding: '6px 12px',
    //border: '1px solid',
    borderRadius: '4px',
    lineHeight: 1.5,
    backgroundColor: '#6ac0b6',
    borderColor: '#6ac0b6',
    '&:hover': {
      backgroundColor: '#49aea2',
      borderColor: '#49aea2',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#6ac0b6',
      borderColor: '#6ac0b6',
    },
  },
}

export const StudentProfilView: React.FunctionComponent<ViewProps> = ({ studentResponse, classes, context }: ViewProps) => {
  const isCollegeUser = () => {
    return context.state.user.account.type === 'college';
  }

    return (
        <div>
            <div className={classes.title}> Fiche de suivi </div>
            {studentResponse.status === 'loading' && <CircularLoader />}
            {studentResponse.status === 'success' &&
                <div>
                    <Grid container item xs={12} style={{ paddingLeft: "15px" }}>
                        <Grid container style={{ paddingBottom: "15px" }} >
                          <Grid item className={classes.iconAlign}>
                            <FaceIcon fontSize="small" />
                            <div className={classes.textAlign}> {studentResponse.payload.account.last_name} </div> <div>&nbsp;{studentResponse.payload.account.first_name}</div>
                          </Grid>
                        </Grid>
                        <Grid container style={{ paddingBottom: "15px" }}>
                          <Grid item className={classes.iconAlign}>
                            <PhoneIcon fontSize="small"/>
                            <div className={classes.textAlign}> {studentResponse.payload.account.phone} </div>
                          </Grid>
                        </Grid>
                        <Grid container style={{ paddingBottom: "15px" }}>
                          <Grid item className={classes.iconAlign}>
                            <AlternateEmailIcon fontSize="small"/>
                            <div className={classes.textAlign}>{studentResponse.payload.email}</div>
                          </Grid>
                        </Grid>
                        {studentResponse.payload.account.rapport && isCollegeUser() ?
                          <Grid container style={{ paddingBottom: "15px" }}>
                            <Grid item>
                              <a
                                className={classes.button}
                                href={studentResponse.payload.account.rapport}
                                rel="noopener noreferrer"
                                target="_blank">
                                <DescriptionIcon style={{color: "#FFFFFF"}} fontSize="small"/>
                                <div style={{color: "#FFFFFF"}} className={classes.textAlign}>Rapport de Stage</div>
                              </a>
                            </Grid>
                          </Grid>
                        : null}
                        {studentResponse.payload.account.convention && isCollegeUser() ?
                          <Grid container style={{ paddingBottom: "15px" }}>
                            <Grid item>
                              <a
                                className={classes.button}
                                href={studentResponse.payload.account.convention}
                                rel="noopener noreferrer"
                                target="_blank">
                                <DescriptionIcon style={{color: "#FFFFFF"}} fontSize="small"/>
                                <div style={{color: "#FFFFFF"}} className={classes.textAlign}>Convention de Stage</div>
                              </a>
                            </Grid>
                          </Grid>
                        : null}
                    </Grid>
                </div>
            }
        </div>
    )
}

export const StyledStudentProfilView = withStyles(styles)(StudentProfilView);

type IProps = {
    studentId: string
}

const WrappedStudentProfilView: React.FunctionComponent<IProps> = ({ studentId }: IProps) => {
    // HOOKS
    const studentResponse: WrappedResponse<Student> = useStudentGet(studentId)

    return (
      <Context.Consumer>
        {(context) => <StyledStudentProfilView studentResponse={studentResponse} context={context} />}
      </Context.Consumer>
      )
}

export default WrappedStudentProfilView;
