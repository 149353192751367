import React from 'react';
import PropTypes from 'prop-types';

// javascript plugin used to create scrollbars on windows
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// core components
import sidebarStyle from 'components/Sidebar/style/sidebarStyle';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { isPerfectScrollbar } from 'helpers/helpers';
import { isPast } from 'date-fns';
import { tutorGuide } from '../../data';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: true,
    };
    this.activeRoute.bind(this);
  }

  handleNavClick = (e) => {
    const debug = {
      target: e.target,
      className: e.target.className,
      dataset: e.target.dataset,
      click_on: {
        click_on_icon: e.target.className instanceof SVGAnimatedString,
        click_on_clickable_element: e.target.dataset.clickable === 'true',
      },
    };

    Object.values(debug.click_on).some((_) => _) && this.props.handleDrawerClose();
  };

  checkIfAccepted = () => {
    return this.props.context.state.user.hired;
  };

  checkInternshipIsOver = () => {
    return this.checkIfAccepted() && isPast(Date.parse(this.props.context.state.user.account.internship_period[1]));
  };

  //verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    if (routeName === '/annonces' && this.props.location.pathname.split('/')[1] === 'details-annonce') {
      return true;
    }
    return this.props.location.pathname.indexOf(routeName) > -1;
  }

  handleNavLink(e, disabled = false) {
    disabled && e.preventDefault();
  }

  openCollapse(collapse) {
    let st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  renderName = () => {
    const { type, first_name, last_name, college } = this.props.context.state.user.account;

    if (['student', 'pro', 'hr', 'guest', 'association'].includes(type)) {
      return `${first_name.toUpperCase()} ${last_name.toUpperCase()}`;
    } else if (type === 'college') {
      return college.name.toUpperCase();
    } else if (type === 'administrator') {
      return `${first_name.toUpperCase()}`;
    }
  };

  render() {
    const { classes, image, routes } = this.props;
    const { account } = this.props.context.state.user;

    const itemText = cx({
      [classes.itemText]: true,
    });
    const userWrapperClass = cx({
      [classes.user]: true,
    });

    const user = (
      <div className={userWrapperClass}>
        <List className={`${classes.list} ${classes.whiteSep}`}>
          <ListItem className={`${classes.item} ${classes.welcomeItem}`}>Bienvenue,</ListItem>
          <ListItem className={`${classes.item} ${classes.userItem}`}>
            <ListItemText
              primary={this.renderName()}
              disableTypography={true}
              className={`${itemText} ${classes.userItemText}`}
            />
          </ListItem>
          <ListItem className={`${classes.item} ${classes.functionItem}`}>
            {account.type === 'hr' && account.company.name}
            {account.type === 'pro' && account.company.name}
            {account.type === 'student' && account.college.name}
            {account.type === 'association' && account.association.name.split('à')[0].replace('Association ', '')}
          </ListItem>

          {routes.map((prop, key) => {
            if (prop.pathTo) {
              return null;
            }

            if (prop.user && prop.path !== '/deconnexion') {
              const navLinkClasses = cx({
                [classes.itemLink]: true,
              });
              const itemText = cx({
                [classes.itemText]: true,
                [classes.itemTextActive]: this.activeRoute(prop.path),
              });
              const itemIcon = cx({
                [classes.itemIcon]: true,
              });

              let disabledLink = false;

              // disable every menu for pro that didn't signed charter
              if (
                this.props.context.state.user.account.type === 'pro' &&
                !this.props.context.state.user.account.has_signed_charter
              ) {
                disabledLink = true;
                // but enable /modifier-profil for impersonating admin
                if (prop.path === '/modifier-profil' && localStorage.getItem('admin')) {
                  disabledLink = false;
                }
              }

              return (
                <ListItem key={key} className={classes.item} onClick={this.props.handleDrawerToggle}>
                  <NavLink
                    to={prop.path}
                    className={!disabledLink ? navLinkClasses : `${navLinkClasses} disabled`}
                    onClick={(e) => this.handleNavLink(e, disabledLink)}>
                    <ListItemIcon className={itemIcon}>
                      <prop.icon />
                    </ListItemIcon>
                    <ListItemText primary={prop.name} disableTypography={true} className={itemText} />
                  </NavLink>
                </ListItem>
              );
            }
            return null;
          })}
        </List>
      </div>
    );
    const links = (
      <List className={`${classes.list} ${classes.whiteSep}`}>
        {routes.map((prop, key) => {
          if (!prop.user && !prop.redirect && !prop.invisible) {
            const navLinkClasses = cx({
              [classes.itemLink]: true,
            });
            const itemText = cx({
              [classes.itemText]: true,
              [classes.itemTextActive]: this.activeRoute(prop.path),
            });
            const itemIcon = cx({
              [classes.itemIcon]: true,
            });
            let disabledLink = false;

            // disable rules menu for pro that didn't signed charter
            if (
              this.props.context.state.user.account.type === 'pro' &&
              !this.props.context.state.user.account.has_signed_charter
            ) {
              // only enable /annonces for impersonating admin or only enable /charte for pro
              if (
                (prop.path !== '/charte' && !localStorage.getItem('admin')) ||
                (prop.path !== '/annonces' && localStorage.getItem('admin'))
              ) {
                disabledLink = true;
              }
            }

            if (this.props.context.state.user.account.type === 'student') {
              // enable convention menu if at least one application is accepted
              // or conventionMenu is enabled by admin
              if (prop.path === '/uploadConvention') {
                disabledLink = true;
                if (this.checkIfAccepted() || this.props.context.state.user.conventionMenu === true) {
                  disabledLink = false;
                }
              }

              // enable rapport menu if internship is over
              if (prop.path === '/rapport') {
                disabledLink = true;
                if (this.checkInternshipIsOver()) {
                  disabledLink = false;
                }
              }

              // enable messages menu if at least one application is accepted
              // or student already received a message
              if (prop.path === '/messages') {
                disabledLink = true;
                if (this.checkIfAccepted() || this.props.context.state.user.has_messages) {
                  disabledLink = false;
                }
              }
            }

            return (
              <ListItem key={key} className={classes.item}>
                <NavLink
                  data-clickable
                  to={prop.path}
                  onClick={(e) => this.handleNavLink(e, disabledLink)}
                  className={!disabledLink ? navLinkClasses : `${navLinkClasses} disabled`}>
                  <ListItemIcon className={itemIcon}>
                    <prop.icon />
                  </ListItemIcon>
                  <ListItemText data-clickable primary={prop.name} disableTypography={true} className={itemText} />
                </NavLink>
              </ListItem>
            );
          }
          return null;
        })}
      </List>
    );

    const logout = (
      <List className={classes.list}>
        {this.props.context.state.user.account.type === 'pro' && (
          <ListItem className={classes.item}>
            <NavLink
              className={classes.itemLink}
              target='_blank'
              rel='noopener noreferrer'
              to={{ pathname: tutorGuide }}>
              <ListItemIcon className={classes.itemIcon}>
                <OpenInNewIcon />
              </ListItemIcon>
              <ListItemText primary={'Guide Tuteur'} disableTypography={true} className={classes.itemText} />
            </NavLink>
          </ListItem>
        )}
        {routes.map((prop, key) => {
          if (prop.redirect || prop.invisible) {
            return null;
          }

          // noinspection DuplicatedCode
          if (prop.user && prop.path === '/deconnexion') {
            const navLinkClasses = cx({
              [classes.itemLink]: true,
            });
            const itemText = cx({
              [classes.itemText]: true,
              [classes.itemTextActive]: this.activeRoute(prop.path),
            });
            const itemIcon = cx({
              [classes.itemIcon]: true,
            });
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink to={prop.path} className={navLinkClasses} onClick={this.props.handleDrawerToggle}>
                  <ListItemIcon className={itemIcon}>
                    <prop.icon />
                  </ListItemIcon>
                  <ListItemText primary={prop.name} disableTypography={true} className={itemText} />
                </NavLink>
              </ListItem>
            );
          }
          return null;
        })}
      </List>
    );

    const brand = (
      <a href={__ENV__.PUBLIC_URL} style={{ margin: '20px auto' }}>
        <img
          style={{ width: '150px' }}
          src={`${__ENV__.PUBLIC_URL}/logo-white-sidebar.svg`}
          alt='logo'
          className={classes.img}
        />
      </a>
    );

    const drawerPaper = cx({
      [classes.drawerPaper]: true,
      [classes.drawerPaperRight]: !this.props.newHeader,
    });
    const sidebarWrapper = cx({
      [classes.sidebarWrapper]: true,
      //   navigator.platform.indexOf('Win') > -1
    });

    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
      // eslint-disable-next-line react/no-string-refs
      <div ref='mainPanel'>
        <Hidden mdUp>
          <SwipeableDrawer
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            variant='temporary'
            anchor={this.props.newHeader ? 'left' : 'right'}
            open={this.props.open}
            classes={{
              paper: `${drawerPaper} ${classes['whiteBackground']}`,
            }}
            onClick={this.handleNavClick}
            onOpen={() => {}}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            {brand}
            {isPerfectScrollbar() ? (
              <div className={sidebarWrapper}>
                <PerfectScrollbar>
                  {user}
                  {links}
                  {logout}
                </PerfectScrollbar>
              </div>
            ) : (
              <div className={`${sidebarWrapper} scrollbar`}>
                {user}
                {links}
                {logout}
              </div>
            )}
            {image !== undefined ? (
              <div className={classes.background} style={{ backgroundImage: `url(${image})` }} />
            ) : null}
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            anchor='left'
            variant='permanent'
            open
            classes={{
              paper: `${drawerPaper} ${classes['whiteBackground']}`,
            }}
            onClick={this.handleNavClick}>
            {brand}
            {isPerfectScrollbar() ? (
              <div className={sidebarWrapper}>
                <PerfectScrollbar>
                  {user}
                  {links}
                  {logout}
                </PerfectScrollbar>
              </div>
            ) : (
              <div className={`${sidebarWrapper} scrollbar`}>
                {user}
                {links}
                {logout}
              </div>
            )}

            {image !== undefined ? (
              <div className={classes.background} style={{ backgroundImage: `url(${image})` }} />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(sidebarStyle)(Sidebar);
