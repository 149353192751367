// @material-ui/icons
import Settings from '@material-ui/icons/Settings';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import SearchIcon from '@material-ui/icons/Search';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import Description from '@material-ui/icons/Description';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SchoolIcon from '@material-ui/icons/School';

export const routes = [
  {
    user: true,
    path: '/modifier-profil',
    name: 'Modifier mon profil',
    icon: Settings,
  },
  {
    user: true,
    path: '/deconnexion',
    name: 'Déconnexion',
    icon: PowerSettingsNew,
  },
  {
    path: '/annonces',
    name: 'Trouver un stage',
    icon: SearchIcon,
  },
  {
    path: '/messages',
    name: 'Mes messages',
    icon: InsertCommentIcon,
  },
  {
    path: '/candidatures',
    name: 'Mes candidatures',
    icon: Description,
  },
  {
    path: '/association',
    name: 'Mon association',
    icon: SupervisorAccountIcon,
  },
  {
    path: '/uploadConvention',
    name: 'Ma convention',
    icon: SchoolIcon,
  },
  {
    path: '/rapport',
    name: 'Mon rapport de stage',
    icon: Description,
  },
];
