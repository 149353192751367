import {
  primaryColor,
  blackColor,
  successColor,
  helpText,
  containerOnlyMobile
} from '../../../assets/styles/jss/main';
import { StyleRules } from '@material-ui/core/styles';

const profilPageStyle: StyleRules = {
  formContainer: {
    marginTop: '5vh'
  },
  title: {
    ...containerOnlyMobile,
    paddingTop: 15,
    margin: 0,
    fontSize: 28,
    textTransform: 'uppercase'
  },
  labelHorizontal: {
    width: '100%',
    paddingTop: '15px',
    color: '#000000',
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 400,
    marginRight: '0',
    '@media (min-width: 992px)': {
      float: 'right'
    }
  },
  label: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    margin: "10px 0",
  },
  footerButton: {
    margin: '5vh 5px 0 5px'
  },
  textCenter: {
    textAlign: 'center'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%'
  },
  logoImg: {
    maxHeight: 100,
    maxWidth: 100
  },
  borderButton: {
    border: `1px solid ${primaryColor}`,
    color: primaryColor,
    margin: '10px 10px 0 20px',
    '@media (max-width: 600px)': {
      marginLeft: '15px'
    }
  },
  borderPictureLoading: {
    border: 'none',
    boxShadow: 'none'
  },
  // TABLE : specific for Pro
  checked: {
    color: primaryColor + '!important'
  },
  checkedIcon: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px'
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px'
  },
  switchDisplay: {
    marginBottom: 24,
    // display: 'inline-flex',
  },
  colorSwitchBase: {
    '&$colorChecked': {
      color: successColor,
      '& + $colorBar': {
        backgroundColor: successColor,
      },
    },
  },
  colorChecked: {},
  labelSwitch: {
    display: 'flex',
    alignItems: 'center',
    width: '8px',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  titleTable: {
    fontSize: 18,
    margin: '10px 0 10px 10px'
  },
  // PRO (specific)
  listStyleNone: {
    paddingLeft: 0,
    marginTop: 0,
    '& li': {
      listStyleType: 'none'
    }
  },
  helpText: {
    ...helpText
  },
  datesWrapper: {
    display: 'flex'
  },
  datesLeft: {
    flex: 1,
    marginRight: 5
  },
  datesRight: {
    flex: 1,
    marginLeft: 5
  },
  separator: {
    height: 50
  },
  explanationDatePicker: {
    //...customFont,
    marginTop: 15
  },
  // Ajout ou selection d'une entreprise (Pro&HR specific)
  selectCompanyButtonsWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  selectCompanyButtons: {
    color: blackColor,
    fontSize: 16,
    fontWeight: 400
  },
  selectCompanyButtonsError: {
    color: '#f44336'
  },
  cardWithSeparation: {
    marginTop: '2rem'
  },
  formItem: {
    '@media (min-width: 800px)': {
      paddingBottom: '20px !important'
    }
  },
  profilCard: {
    padding: 20,
    border: '1px solid #ececee',
    '@media (min-width: 800px)': {
      maxWidth: 384,
      marginBottom: '1rem'
    }
  },
  withHelpTextOutsideCard: {
    position: 'relative'
  },
  helpTextCanBeOutsideCard: {
    marginBottom: '.3rem',
    '@media screen and (min-width: 800px)': {
      position: 'absolute',
      top: 0,
      left: 390,
      width: '95%',
      maxWidth: 450,
      fontStyle: 'italic'
    }
  },
  nameHelper: {
    fontSize: 14,
    height: 39,
    lineHeight: 2.5
  },
  externalLink: {
    color: primaryColor,
    fontWeight: 700,
    textDecoration: 'underline',
    '&:hover, &:active, &:focus': {
      color: primaryColor,
      textDecoration: 'underline'
    }
  },
  alignToInput: {
    fontSize: 14,
    '@media screen and (max-width: 959.97px)': {
      paddingBottom: '20px !important'
    },
    '@media screen and (min-width: 960px)': {
      paddingTop: 40
    }
  },
  alignToBottom: {
    fontSize: 14,
    '@media screen and (max-width: 959.97px)': {
      paddingBottom: '20px !important'
    },
    '@media screen and (min-width: 960px)': {
      alignSelf: 'center'
    }
  },
  bigSep: {
    marginBottom: 15
  }
};

export default profilPageStyle;
