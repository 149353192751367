import { Box, StyleRules, WithStyles, withStyles } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import React from 'react';
import MultipleWeeksPicker from '../../components/CustomInput/MultipleWeeksPicker';
import GridContainer from '../../components/Grid/GridContainer';
import ItemGrid from '../../components/Grid/ItemGrid';

const InfoCollegeForm: React.FC<IProps> = ({ college, initWeekPeriod, classes, onSelect }: IProps) => {
  return (<form className={classes.formContainer}>
    <GridContainer>
      <ItemGrid xs={12} sm={3} className={classes.labelDisapear}>
        <Box >
          Collège
        </Box>
      </ItemGrid>
      <ItemGrid xs={12} sm={7}>
        <Box className={classes.collegeName}>
        {college} 
        </Box>
      </ItemGrid>

      <ItemGrid xs={12} sm={3}>
        <FormLabel className={classes.labelHorizontal}>
          Période de stage
        </FormLabel>
      </ItemGrid>
      <ItemGrid xs={12} sm={7}>
        {initWeekPeriod && <MultipleWeeksPicker key='MultipleWeeksPicker' disablePast={true} limit={3} onSelect={onSelect} value={initWeekPeriod} isCollegeForm/>}
      </ItemGrid>
    </GridContainer>
  </form>);
}


const profilPageStyle: StyleRules = {
  formContainer: {
    marginTop: '5vh'
  },
  labelDisapear: {
    '@media (max-width: 600px)': {
      display: 'none'
    },
  },
  collegeName: {
    height: '42px',
    marginBottom: '10px'
  },
  labelHorizontal: {
    width: '100%',
    paddingTop: '15px',
    color: '#000000',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 400,
    marginRight: '0',
    '@media (min-width: 992px)': {
      float: 'right'
    }
  }
};

interface IProps extends WithStyles<typeof profilPageStyle> {
  college: any,
  initWeekPeriod: any,
  classes: any, 
  onSelect: (periods) => void,
}

export default withStyles(profilPageStyle)(InfoCollegeForm);

